import { Popover } from "flowbite";
import { Controller } from "@hotwired/stimulus";
import { setUrlParameterAndReload } from "../src/custom/UrlParams";

export default class extends Controller {
  static targets = ["menu", "trigger"];

  popoverOptions = {
    placement: "bottom",
    triggerType: "click",
    offset: 0,
    onHide: () => {
      this.setVisibility("invisible");
    },
    onShow: () => {
      this.setVisibility("visible");
    },
  };

  connect() {
    if (!this.popoverInstance) {
      this.popoverInstance = new Popover(
        this.menuTarget,
        this.triggerTarget,
        this.popoverOptions,
      );
    }
    this.popoverInstance.hide();
  }

  disconnect() {
    if (this.popoverInstance) {
      this.popoverInstance.destroy();
      this.popoverInstance = null;
    }
  }

  // Internal methods
  getVisibility() {
    return this.menuTarget.getAttribute("data-visibility");
  }

  setVisibility(value) {
    this.menuTarget.setAttribute("data-visibility", value);
  }

  toggleVisibility() {
    if (this.getVisibility() === "visible") {
      this.setVisibility("invisible");
    } else {
      this.setVisibility("visible");
    }
  }

  // Event handlers
  changeLanguage(event) {
    event.preventDefault();
    setUrlParameterAndReload("locale", event.params.language);
    event.stopImmediatePropagation();
  }

  toggleMenu(event) {
    if (this.popoverInstance.isVisible()) {
      if (this.getVisibility() === "invisible") {
        this.popoverInstance.hide();
      }
    } else {
      this.popoverInstance.show();
    }
    this.toggleVisibility();
    event.stopImmediatePropagation();
  }
}
